exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shared-cv-js": () => import("./../../../src/pages/shared/cv.js" /* webpackChunkName: "component---src-pages-shared-cv-js" */),
  "component---src-pages-shared-index-js": () => import("./../../../src/pages/shared/index.js" /* webpackChunkName: "component---src-pages-shared-index-js" */),
  "component---src-pages-shared-play-index-js": () => import("./../../../src/pages/shared/play/index.js" /* webpackChunkName: "component---src-pages-shared-play-index-js" */),
  "component---src-pages-shared-portfolio-contentful-project-slug-js": () => import("./../../../src/pages/shared/portfolio/{ContentfulProject.slug}.js" /* webpackChunkName: "component---src-pages-shared-portfolio-contentful-project-slug-js" */),
  "component---src-pages-shared-portfolio-index-js": () => import("./../../../src/pages/shared/portfolio/index.js" /* webpackChunkName: "component---src-pages-shared-portfolio-index-js" */)
}

